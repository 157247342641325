// components/auth/UserMenu.js
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Settings, LogOut } from 'lucide-react';
import { useAuth } from './AuthContext';
import '../../css/UserMenu.css';

const UserMenu = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const handleAccountSettings = () => {
    navigate('/account');
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        setIsMenuOpen(false);
      }
    };

    if (isMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleEscape);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isMenuOpen]);

  return (
    <div className="user-menu-container" ref={menuRef}>
      <button
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        className="user-menu-trigger"
        aria-expanded={isMenuOpen}
        aria-haspopup="true"
      >
        <div className="user-avatar">
          {user?.email[0].toUpperCase()}
        </div>
        <span className="user-email" title={user?.email}>
          {user?.email}
        </span>
      </button>

      <div className={`user-menu-dropdown ${isMenuOpen ? 'active' : ''}`}>
        <div className="user-menu-content">
          <div className="user-menu-header">
            <div className="user-menu-email" title={user?.email}>
              {user?.email}
            </div>
          </div>
          
          <div className="menu-group">
            <button
              onClick={handleAccountSettings}
              className="menu-item"
            >
              <Settings className="menu-icon" aria-hidden="true" />
              <span>Settings</span>
            </button>
          </div>
          
          <div className="menu-group">
            <button
              onClick={handleLogout}
              className="menu-item menu-item-danger"
            >
              <LogOut className="menu-icon" aria-hidden="true" />
              <span>Sign Out</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserMenu;