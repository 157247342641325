// components/MainLayout.js
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { BookmarkIcon, NewspaperIcon } from 'lucide-react';
import UrlForm from './UrlForm';
import FavoritesList from './FavoritesList';
import NewsList from './NewsList';
import '../css/MainLayout.css';

const PASTEL_COLORS = [
    '#FFE7C1', '#C8E1FF', '#FFEDDE', '#D7F3E3', 
    '#FCE2FF', '#FFF5C1', '#D7CFFF'
];

const MainLayout = ({
    url,
    setUrl,
    handleSubmit,
    loading,
    favorites,
    setFavorites,        
    setFilteredFavorites, 
    categories,
    filterFavoritesByCategory
}) => {
    const [activeView, setActiveView] = useState(null);  // null for split view
    const [activeCategory, setActiveCategory] = useState(null);
    const [expandedItemUrl, setExpandedItemUrl] = useState(null);
    const handleSubmitRef = useRef(handleSubmit);

    // URL 处理逻辑
    useEffect(() => {
        handleSubmitRef.current = handleSubmit;
    }, [handleSubmit]);

    const handlePendingUrl = useCallback(() => {
        const pendingUrl = sessionStorage.getItem('pendingUrl');
        if (pendingUrl && handleSubmitRef.current) {
            setUrl(pendingUrl);
            sessionStorage.removeItem('pendingUrl');
            setTimeout(() => {
                handleSubmitRef.current({ preventDefault: () => {} });
            }, 100);
        }
    }, [setUrl]);

    useEffect(() => {
        if (handleSubmitRef.current) {
            handlePendingUrl();
        }
    }, [handlePendingUrl]);

    // 分类管理
    const handleCategoryClick = useCallback((cat) => {
        setActiveCategory(prevCat => {
            const newCat = prevCat === cat ? null : cat;
            filterFavoritesByCategory(newCat);
            return newCat;
        });
    }, [filterFavoritesByCategory]);

    const clearCategorySelection = useCallback((e) => {
        e?.stopPropagation();
        setActiveCategory(null);
        filterFavoritesByCategory(null);
    }, [filterFavoritesByCategory]);

    const getCategoryColor = useCallback((category) => {
        const index = categories.indexOf(category);
        return index >= 0 ? PASTEL_COLORS[index % PASTEL_COLORS.length] : '#FFFFFF';
    }, [categories]);

    useEffect(() => {
        if (!activeView || activeView === 'news') {
            setExpandedItemUrl(null);
        }
    }, [activeView]);

    const toggleExpand = useCallback((url) => {
        if (expandedItemUrl === url) {
            const expandedElement = document.querySelector(`[data-url="${url}"]`);
            if (expandedElement) {
                const elementTop = expandedElement.getBoundingClientRect().top;
                
                setExpandedItemUrl(null);
                
                requestAnimationFrame(() => {
                    const collapsedElement = document.querySelector(`[data-url="${url}"]`);
                    if (collapsedElement) {
                        const newTop = collapsedElement.getBoundingClientRect().top;
                        const scrollAdjustment = newTop - elementTop;
                        
                        window.scrollBy({
                            top: scrollAdjustment,
                            behavior: 'smooth'
                        });
                    }
                });
            }
        } else {
            setExpandedItemUrl(url);
            setActiveView('saved');
            
            setTimeout(() => {
                const expandedElement = document.querySelector('.expanded-item-container');
                if (expandedElement) {
                    expandedElement.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                    });
                }
            }, 100);
        }
    }, [expandedItemUrl, setActiveView]);

    return (
        <div className="main-layout">
            {/* URL Input Section */}
            <div className="card">
                <div className="card-content">
                    <UrlForm 
                        url={url} 
                        setUrl={setUrl} 
                        handleSubmit={handleSubmit} 
                        loading={loading} 
                    />
                    {loading && (
                        <p className="loading-message">
                            Processing your request, please wait...
                        </p>
                    )}
                </div>
            </div>

            {/* Navigation */}
            <div className="nav-tabs">
                <div className="tabs-container">
                    <button 
                        className={`tab-button ${activeView === 'saved' ? 'active' : ''}`}
                        onClick={() => setActiveView(activeView === 'saved' ? null : 'saved')}
                    >
                        <BookmarkIcon className="tab-icon" />
                        <span>Saved Articles</span>
                    </button>
                    <button 
                        className={`tab-button ${activeView === 'news' ? 'active' : ''}`}
                        onClick={() => setActiveView(activeView === 'news' ? null : 'news')}
                    >
                        <NewspaperIcon className="tab-icon" />
                        <span>Related News</span>
                    </button>
                </div>
            </div>

            {/* Content Area */}
            <div className={`content-grid ${activeView ? 'single-column' : 'split-view'}`}>
                {/* Saved Articles */}
                {(!activeView || activeView === 'saved') && (
                    <div className="card content-card">
                        <FavoritesList 
                            favorites={favorites} 
                            setFavorites={setFavorites}
                            setFilteredFavorites={setFilteredFavorites} 
                            getCategoryColor={getCategoryColor}
                            categories={categories}
                            activeCategory={activeCategory}
                            handleCategoryClick={handleCategoryClick}
                            clearCategorySelection={clearCategorySelection}
                            setActiveView={setActiveView}
                            activeView={activeView}
                            expandedItemUrl={expandedItemUrl}
                            setExpandedItemUrl={setExpandedItemUrl}
                            toggleExpand={toggleExpand}
                        />
                    </div>
                )}

                {/* Related News */}
                {(!activeView || activeView === 'news') && (
                    <div className="card content-card">
                        <NewsList />
                    </div>
                )}
            </div>
        </div>
    );
};

export default MainLayout;