// src/legal/TermsOfService.js
import React from "react";

const TermsOfService = () => {
  return (
    <div className="max-w-3xl mx-auto p-6">
      <h1 className="text-2xl font-bold mb-6">Terms of Service</h1>

      <p className="mb-6">Welcome to linksort.ai. These terms govern your use of our link summarization service.</p>

      <h2 className="text-xl font-semibold mb-4">Service Usage</h2>
      <ul className="list-disc pl-6 mb-6">
        <li>You can use our service with or without an account</li>
        <li>Anonymous usage is limited to browser-based storage</li>
        <li>You can convert anonymous data to an account at any time</li>
        <li>You are responsible for the content you submit for summarization</li>
      </ul>

      <h2 className="text-xl font-semibold mb-4">User Accounts</h2>
      <p className="mb-6">
        We offer two types of usage:
      </p>
      <ul className="list-disc pl-6 mb-6">
        <li>Anonymous: Uses browser identification to save your data</li>
        <li>Google Sign-In: Allows access across devices and browsers</li>
      </ul>

      <h2 className="text-xl font-semibold mb-4">Data Usage</h2>
      <p className="mb-6">
        We only collect and store information necessary for the service to function:
      </p>
      <ul className="list-disc pl-6 mb-6">
        <li>Submitted links and their summaries</li>
        <li>Basic account information (for logged-in users)</li>
        <li>Browser identifier (for anonymous users)</li>
      </ul>

      <h2 className="text-xl font-semibold mb-4">Acceptable Use</h2>
      <p className="mb-6">You agree to:</p>
      <ul className="list-disc pl-6 mb-6">
        <li>Submit only links you have the right to access</li>
        <li>Not use the service for any illegal purposes</li>
        <li>Not attempt to circumvent any service limitations</li>
      </ul>

      <h2 className="text-xl font-semibold mb-4">Service Limitations</h2>
      <p className="mb-6">We reserve the right to:</p>
      <ul className="list-disc pl-6 mb-6">
        <li>Modify or discontinue the service at any time</li>
        <li>Remove content that violates these terms</li>
        <li>Update these terms as needed</li>
      </ul>

      <h2 className="text-xl font-semibold mb-4">Contact</h2>
      <p className="mb-4">Questions about these terms: support@linksort.ai</p>
    </div>
  );
};

export default TermsOfService;