// components/FavoritesList.js
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteFavoriteFromIndexedDB, saveFavoritesToIndexedDB } from './indexeddb';
import { syncFavorites } from './syncFavorites';
import '../css/Favorites.css';
import { 
    processImageUrl, 
    handleImageError, 
    handleImageLoad,
    getImageContainerStyle,
    getImageStyle
} from './utils/imageUtils';
import '../css/Favorites.css';
import Masonry from 'react-masonry-css';
import { FaComments, FaTrash, FaChevronDown, FaChevronUp } from 'react-icons/fa';

function FavoritesList({ 
    favorites, 
    setFavorites, 
    setFilteredFavorites, 
    getCategoryColor,
    categories,
    activeCategory,
    handleCategoryClick,
    clearCategorySelection,
    setActiveView,
    activeView,
    expandedItemUrl,
    setExpandedItemUrl,
    toggleExpand
}) {
    const [searchTerm, setSearchTerm] = useState('');
    const [showCategories, setShowCategories] = useState(false);
    const dropdownRef = useRef(null);
    const navigate = useNavigate();

    // Add click outside handler
    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowCategories(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Sort favorites by created_at in descending order
    const sortedFavorites = [...favorites].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    // 实时过滤收藏列表
    const filteredFavorites = sortedFavorites.filter(fav =>
        fav.summary.toLowerCase().includes(searchTerm.toLowerCase()) || 
        fav.url.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // 处理删除收藏
    const handleDeleteFavorite = async (favoriteToDelete) => {
        // 添加确认弹窗
        if (!window.confirm('Are you sure you want to delete this favorite?')) {
            return;
        }

        try {
            await deleteFavoriteFromIndexedDB(favoriteToDelete.url);
            const newFavorites = favorites.filter(fav => fav.url !== favoriteToDelete.url);
            setFavorites(newFavorites);
            setFilteredFavorites(newFavorites);
            await saveFavoritesToIndexedDB(newFavorites);
            
            window.dispatchEvent(new CustomEvent('favoriteChanged', {
                detail: {
                    type: 'delete',
                    url: favoriteToDelete.url
                }
            }));
        } catch (error) {
            console.error('Error deleting favorite:', error);
            alert('Failed to delete favorite. Please try again.');
        }
    };

    useEffect(() => {
        const syncData = async () => {
            try {
                const syncedFavorites = await syncFavorites();
                setFavorites(syncedFavorites);
            } catch (error) {
                console.error('Error syncing favorites:', error);
            }
        };
    
        syncData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 

    useEffect(() => {
        console.log('Favorites data:', favorites);
        favorites.forEach(fav => {
            if (fav.main_image_url) {
                console.log(`Favorite has image URL: ${fav.main_image_url}`);
            }
        });
    }, [favorites]);

    const renderFavoriteItem = (fav, index, isExpanded) => {
        const paragraphs = fav.summary.split('\n\n');
        const isNew = fav.isNew;

        return (
            <li 
                key={fav.url} 
                data-url={fav.url}
                className={`${isExpanded ? 'expanded-item' : ''} ${isNew ? 'isNew' : ''}`}
                style={{ 
                    borderColor: getCategoryColor(fav.category), 
                    borderWidth: '4px', 
                    borderStyle: 'solid', 
                    borderRadius: '12px', 
                    padding: '15px',
                    overflow: 'hidden'
                }}
            >
                <div className="favorite-preview">
                    {/* 预览内容 */}
                    {fav.main_image_url && (
                        <div 
                            className="article-main-image" 
                            style={{
                                ...getImageContainerStyle(),
                                cursor: 'pointer'
                            }}
                            onClick={() => window.open(fav.url, '_blank')}
                            role="button"
                            tabIndex={0}
                            data-tooltip="Click to view original article"
                        >
                            <img 
                                src={processImageUrl(fav.main_image_url)} 
                                alt={fav.title || 'Article thumbnail'}
                                style={getImageStyle()}
                                onError={(e) => handleImageError(e, fav.main_image_url)}
                                onLoad={(e) => handleImageLoad(e, fav.main_image_url)}
                                crossOrigin="anonymous"
                            />
                        </div>
                    )}
                    
                    <a href={fav.url} target="_blank" rel="noopener noreferrer">
                        {fav.url.length > 50 ? `${fav.url.substring(0, 50)}...` : fav.url}
                    </a>
                    {fav.title && (
                        <h2 
                            onClick={() => toggleExpand(fav.url)}
                            style={{ cursor: 'pointer' }}
                            role="button"
                            tabIndex={0}
                            data-tooltip="Show Summary"
                        >
                            {fav.title}
                        </h2>
                    )}

                    {/* 展开或新收藏时显示完整内容 */}
                    {(isNew || isExpanded) && (
                        <div className="favorite-full-content">
                            {paragraphs.map((para, idx) => (
                                <p key={idx}>{para}</p>
                            ))}
                            {Array.isArray(fav.keyPoints) && fav.keyPoints.length > 0 && (
                                <div className="key-points-section">
                                    <h3>Key Points:</h3>
                                    <ul className="key-points-list">
                                        {fav.keyPoints.map((point, kpIndex) => (
                                            <li key={kpIndex} className="key-point-item">
                                                <div className="key-point-index">{kpIndex + 1}</div>
                                                <div className="key-point-content">{point}</div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    )}

                    {/* 始终显示的底部信息 */}
                    <div className="favorite-footer">
                        <p>
                            <span 
                                className="category-tag" 
                                style={{ 
                                    backgroundColor: getCategoryColor(fav.category),
                                    cursor: 'pointer'
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleCategoryClick(fav.category);
                                }}
                                role="button"
                                tabIndex={0}
                                title="Click to filter by this category"
                            >
                                {fav.category}
                            </span>
                            <span style={{ float: 'right' }}>
                                {fav.created_at ? new Date(fav.created_at).toLocaleDateString('en-US', {
                                    year: 'numeric',
                                    month: 'short',
                                    day: 'numeric'
                                }) : ''}
                            </span>
                        </p>
                        <div className="article-actions">
                            <div className="left-actions">
                                <button 
                                    className="icon-button"
                                    onClick={() => handleDeleteFavorite(fav)}
                                    data-tooltip="Delete"
                                >
                                    <FaTrash className="delete-icon" size={20} />
                                </button>
                            </div>
                            <div className="right-actions">
                                <button 
                                    className="icon-button"
                                    onClick={() => navigate(`/chat/${encodeURIComponent(fav.url)}`)}
                                    data-tooltip="Chat with Article"
                                >
                                    <FaComments className="chat-icon" size={20} />
                                </button>
                                {!isNew && (
                                    <button 
                                        onClick={() => toggleExpand(fav.url)}
                                        className="icon-button"
                                        data-tooltip={isExpanded ? 'Show Less' : 'Show More'}
                                    >
                                        {isExpanded ? 
                                            <FaChevronUp className="expand-icon" size={20} /> : 
                                            <FaChevronDown className="expand-icon" size={20} />
                                        }
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        );
    };

    return (
        <div className="card-content">
            {activeCategory && (
                <div className="active-category">
                    <span>Selected: {activeCategory}</span>
                    <button 
                        className="clear-button"
                        onClick={clearCategorySelection}
                    >
                        ✖
                    </button>
                </div>
            )}

            <div className="favorites-header">
                <div className="header-content">
                    <h2>Favorites:</h2>
                    <div className="categories-dropdown" ref={dropdownRef}>
                        <button 
                            className="all-categories-btn"
                            onClick={() => setShowCategories(!showCategories)}
                        >
                            All Categories
                        </button>
                        {showCategories && (
                            <div className="categories-dropdown-content">
                                <ul className="category-list">
                                    {categories.map((cat, index) => (
                                        <li
                                            key={index}
                                            onClick={() => {
                                                handleCategoryClick(cat);
                                                setShowCategories(false);
                                            }}
                                            className={`category-item ${activeCategory === cat ? 'active' : ''}`}
                                            style={{
                                                backgroundColor: activeCategory === cat ? getCategoryColor(cat) : ''
                                            }}
                                        >
                                            <span className="category-text">{cat}</span>
                                            {activeCategory === cat && (
                                                <button 
                                                    className="clear-button"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        clearCategorySelection(e);
                                                    }}
                                                    aria-label="Clear category selection"
                                                >
                                                    ✖
                                                </button>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="favorites-content">
                {favorites.length > 0 && (
                    <input
                        type="text"
                        placeholder="Search summaries..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="search-input"
                    />
                )}

                {favorites.length === 0 ? (
                    <div className="no-favorites">
                        <i className="fas fa-arrow-up"></i>
                        <p>You haven't summarized any articles yet! Paste a link above to get started.</p>
                    </div>
                ) : filteredFavorites.length === 0 ? (
                    <p>No matching favorites found.</p>
                ) : (
                    <>
                        {/* 找到展开项的索引 */}
                        {(() => {
                            const expandedIndex = filteredFavorites.findIndex(fav => fav.url === expandedItemUrl);
                            
                            return (
                                <>
                                    {/* 展开项之前的内容 */}
                                    <Masonry
                                        breakpointCols={{
                                            default: 2,
                                            768: 1
                                        }}
                                        className="favorites-list"
                                        columnClassName="favorites-list-column"
                                    >
                                        {filteredFavorites.slice(0, expandedIndex !== -1 ? expandedIndex : undefined).map((fav, index) => (
                                            renderFavoriteItem(fav, index, false)
                                        ))}
                                    </Masonry>

                                    {/* 展开的项目 */}
                                    {expandedIndex !== -1 && (
                                        <div className="expanded-item-container">
                                            {renderFavoriteItem(
                                                filteredFavorites[expandedIndex], 
                                                expandedIndex, 
                                                true
                                            )}
                                        </div>
                                    )}

                                    {/* 展开项之后的内容 */}
                                    {expandedIndex !== -1 && (
                                        <Masonry
                                            breakpointCols={{
                                                default: 2,
                                                768: 1
                                            }}
                                            className="favorites-list"
                                            columnClassName="favorites-list-column"
                                        >
                                            {filteredFavorites.slice(expandedIndex + 1).map((fav, index) => (
                                                renderFavoriteItem(
                                                    fav, 
                                                    expandedIndex + index + 1, 
                                                    false
                                                )
                                            ))}
                                        </Masonry>
                                    )}
                                </>
                            );
                        })()}
                    </>
                )}
            </div>
        </div>
    );
}

export default FavoritesList;