// src/legal/PrivacyPolicy.js
import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="max-w-3xl mx-auto p-6">
      <h1 className="text-2xl font-bold mb-6">Privacy Policy</h1>
      
      <p className="mb-6">At linksort.ai, we prioritize your privacy and minimize data collection. This policy explains what limited information we collect and how we use it.</p>

      <h2 className="text-xl font-semibold mb-4">Information We Collect</h2>
      <p className="mb-4">We collect only essential information needed to provide our service:</p>
      <ul className="list-disc pl-6 mb-6">
        <li>For registered users: Email address and password</li>
        <li>For anonymous users: A browser identifier to save your summaries</li>
        <li>Links you submit for summarization</li>
        <li>Your summaries and categorizations</li>
      </ul>

      <h2 className="text-xl font-semibold mb-4">What We Don't Collect</h2>
      <ul className="list-disc pl-6 mb-6">
        <li>IP addresses</li>
        <li>Device information</li>
        <li>Location data</li>
      </ul>

      <h2 className="text-xl font-semibold mb-4">How We Use Information</h2>
      <p className="mb-6">We use your information solely to:</p>
      <ul className="list-disc pl-6 mb-6">
        <li>Authenticate your account</li>
        <li>Provide link summarization services</li>
        <li>Save your summaries for future reference</li>
        <li>Associate summaries with your account (if registered)</li>
      </ul>

      <h2 className="text-xl font-semibold mb-4">Data Storage</h2>
      <p className="mb-6">
        For registered users, your data is stored securely and associated with your account.
        For anonymous users, data is stored using a browser identifier and can be accessed only from the same browser.
        You can delete your data at any time.
      </p>

      <h2 className="text-xl font-semibold mb-4">Third-Party Services</h2>
      <p className="mb-6">We use:</p>
      <ul className="list-disc pl-6 mb-6">
        <li>Email service for account verification and password reset</li>
        <li>AI APIs for summarization (only processes the links you submit)</li>
      </ul>

      <h2 className="text-xl font-semibold mb-4">Your Rights</h2>
      <p className="mb-6">You have the right to:</p>
      <ul className="list-disc pl-6 mb-6">
        <li>Use our service without creating an account</li>
        <li>Access and delete your data at any time</li>
        <li>Convert an anonymous account to a registered account</li>
      </ul>

      <h2 className="text-xl font-semibold mb-4">Contact</h2>
      <p className="mb-4">For privacy-related questions: support@linksort.ai</p>
    </div>
  );
};

export default PrivacyPolicy;

