// src/components/auth/RegisterForm.js

import React from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { Mail, Lock, Eye, EyeOff, AlertCircle, Loader2 } from 'lucide-react';
import { useAuth } from './AuthContext';
import '../../css/RegisterForm.css';

const FormInput = ({
  icon: Icon,
  label,
  register,
  name,
  validation,
  type,
  error,
  showPasswordToggle,
  onTogglePassword,
  showPassword
}) => (
  <div className="form-input-container">
    <label htmlFor={name} className="input-label">
      {label}
    </label>
    <div className="input-wrapper">
      <input
        id={name}
        {...register(name, validation)}
        type={type}
        className={`form-input ${showPasswordToggle ? 'with-toggle' : ''} ${
          error ? 'input-error' : ''
        }`}
      />
      <div className="input-icon-container">
        <Icon className="input-icon" />
      </div>
      {showPasswordToggle && (
        <button
          type="button"
          className="password-toggle-button"
          onClick={onTogglePassword}
          aria-label={showPassword ? 'Hide password' : 'Show password'}
        >
          {showPassword ? (
            <EyeOff className="toggle-icon" />
          ) : (
            <Eye className="toggle-icon" />
          )}
        </button>
      )}
    </div>
    {error && (
      <p className="error-message">{error.message}</p>
    )}
  </div>
);

export const RegisterForm = () => {
  const navigate = useNavigate();
  const { register: registerUser } = useAuth();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [serverError, setServerError] = React.useState('');
  const [fieldErrors, setFieldErrors] = React.useState({});

  const {
    register,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm({
    defaultValues: {
      email: '',
      password1: '',
      password2: ''
    },
    mode: 'onChange' // 启用实时验证
  });

  const password = watch('password1');

  // 处理服务器返回的字段错误
  const handleFieldErrors = (errors) => {
    clearErrors(); // 清除之前的错误
    setFieldErrors({}); // 清除之前的字段错误

    Object.entries(errors).forEach(([field, messages]) => {
      if (Array.isArray(messages) && messages.length > 0) {
        const fieldName = field === 'login' ? 'email' : field; // 映射字段名
        setError(fieldName, {
          type: 'server',
          message: messages[0]
        });
        setFieldErrors(prev => ({
          ...prev,
          [fieldName]: messages[0]
        }));
      }
    });
  };

  // 获取字段错误
  const getFieldError = (fieldName) => {
    return errors[fieldName] || 
           (fieldErrors[fieldName] && { 
             message: fieldErrors[fieldName] 
           });
  };

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    setServerError('');
    clearErrors();
    setFieldErrors({});

    try {
      console.log('Submitting registration data:', data); // 调试日志

      const response = await registerUser({
        email: data.email,
        password1: data.password1,
        password2: data.password2
      });

      console.log('Registration response:', response); // 调试日志

      // 确保响应格式正确
      if (response && response.success) {
        // 根据响应处理不同的导航情况
        if (response.location) {
          if (response.location.includes('verify-email') || response.location === '/verify-email') {
            navigate('/verify-email', {
              state: {
                email: data.email,
                message: response.message || 'Please check your email to verify your account.',
                requiresVerification: true
              },
              replace: true
            });
          } else {
            navigate(response.location, { replace: true });
          }
        } else {
          navigate('/app', { replace: true });
        }
        return;
      }

      // 如果响应中没有 success 字段或为 false
      throw new Error(response?.message || 'Registration failed');

    } catch (error) {
      console.error('Registration error:', error);

      // 处理服务器返回的错误
      if (error.errors) {
        handleFieldErrors(error.errors);
      }

      // 设置通用错误消息
      setServerError(
        error.message || 
        'Registration failed. Please check your information and try again.'
      );

    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="register-page">
      <div className="register-card">
        <div className="card-header">
          <h2 className="title">Create your account</h2>
          <p className="subtitle">
            Already have an account?{' '}
            <Link to="/login" className="link">
              Sign in
            </Link>
          </p>
        </div>

        {serverError && (
          <div className="error-alert">
            <AlertCircle className="alert-icon" />
            <span className="alert-message">{serverError}</span>
          </div>
        )}

        <form className="register-form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="form-fields">
            <FormInput
              icon={Mail}
              label="Email address"
              register={register}
              name="email"
              type="email"
              validation={{
                required: 'Email is required',
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: 'Please enter a valid email'
                }
              }}
              error={getFieldError('email')}
            />

            <FormInput
              icon={Lock}
              label="Password"
              register={register}
              name="password1"
              type={showPassword ? "text" : "password"}
              validation={{
                required: 'Password is required',
                minLength: {
                  value: 8,
                  message: 'Password must be at least 8 characters'
                }
              }}
              error={getFieldError('password1')}
              showPasswordToggle
              onTogglePassword={() => setShowPassword(!showPassword)}
              showPassword={showPassword}
            />

            <FormInput
              icon={Lock}
              label="Confirm password"
              register={register}
              name="password2"
              type={showConfirmPassword ? "text" : "password"}
              validation={{
                required: 'Please confirm your password',
                validate: value => value === password || 'Passwords do not match'
              }}
              error={getFieldError('password2')}
              showPasswordToggle
              onTogglePassword={() => setShowConfirmPassword(!showConfirmPassword)}
              showPassword={showConfirmPassword}
            />
          </div>

          <button
            type="submit"
            disabled={isSubmitting}
            className="submit-button"
          >
            {isSubmitting ? (
              <span className="loading-state">
                <Loader2 className="spinner" />
                <span>Creating account...</span>
              </span>
            ) : (
              'Create account'
            )}
          </button>

          <p className="terms-text">
            By creating an account, you agree to our{' '}
            <Link to="/terms-of-service">Terms of Service</Link>{' '}
            and{' '}
            <Link to="/privacy-policy">Privacy Policy</Link>
          </p>
        </form>
      </div>
    </div>
  );
};

export default RegisterForm;