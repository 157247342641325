// components/auth/AuthMenu.js
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../css/AuthMenu.css';

const AuthMenu = () => {
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsMenuOpen(false);
            }
        };

        const handleEscape = (event) => {
            if (event.key === 'Escape') {
                setIsMenuOpen(false);
            }
        };

        if (isMenuOpen) {
            document.addEventListener('mousedown', handleClickOutside);
            document.addEventListener('keydown', handleEscape);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleEscape);
        };
    }, [isMenuOpen]);

    const handleNavigation = (path) => {
        navigate(path);
        setIsMenuOpen(false);
    };

    return (
        <div className="auth-menu" ref={menuRef}>
            <button 
                className="auth-menu-trigger"
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                aria-expanded={isMenuOpen}
                aria-haspopup="true"
            >
                Sign up / Login
            </button>
            
            <div className={`auth-menu-dropdown ${isMenuOpen ? 'active' : ''}`}>
                <div className="auth-menu-content">
                    <button 
                        className="auth-menu-item"
                        onClick={() => handleNavigation('/signup')}
                    >
                        Sign up
                    </button>
                    <button 
                        className="auth-menu-item"
                        onClick={() => handleNavigation('/login')}
                    >
                        Login
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AuthMenu;