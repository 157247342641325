// components/Toast.js

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const ToastProvider = () => {
    return (
        <ToastContainer 
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            closeOnClick
            pauseOnHover
        />
    );
};

export const showToast = {
    success: (message) => toast.success(message),
    error: (message) => toast.error(message),
    warning: (message) => toast.warning(message),
    info: (message) => toast.info(message)
};

export const handleApiResponse = {
    success: () => {
        showToast.success('Article successfully summarized and added to favorites!');
    },
    error: (error) => {
        const errorMessage = error.response?.data?.message || 
                           'Failed to process the article. Please try again later.';
        showToast.error(errorMessage);
    },
    invalidUrl: () => {
        showToast.error('Invalid URL format. Please enter a valid URL');
    },
    emptyUrl: () => {
        showToast.error('Please enter a valid URL');
    }
}; 