// components/Contact.js
import React, { useState, useEffect } from "react";
import { Mail, MessageCircle, Hash, Slack } from "lucide-react";
import "../css/Contact.css";

const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  const now = new Date();
  const diffInSeconds = Math.floor((now - date) / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);

  if (diffInSeconds < 60) {
    return 'just now';
  } else if (diffInMinutes < 60) {
    return `${diffInMinutes} ${diffInMinutes === 1 ? 'minute' : 'minutes'} ago`;
  } else if (diffInHours < 24) {
    return `${diffInHours} ${diffInHours === 1 ? 'hour' : 'hours'} ago`;
  } else if (diffInDays < 30) {
    return `${diffInDays} ${diffInDays === 1 ? 'day' : 'days'} ago`;
  } else {
    return date.toLocaleDateString('en-US', { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric' 
    });
  }
};

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: ""
  });
  const [status, setStatus] = useState({
    loading: false,
    submitting: false,
    error: null
  });

  const contactMethods = [
    {
      icon: <Mail className="w-6 h-6" />,
      title: "Email",
      description: "support@linksort.ai",
      link: "mailto:support@linksort.ai"
    }
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus(prev => ({ ...prev, submitting: true, error: null }));

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/sort/messages`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          timestamp: new Date().toISOString()
        })
      });

      if (!response.ok) {
        throw new Error('Failed to post message');
      }

      setFormData({
        name: "",
        email: "",
        message: ""
      });

    } catch (error) {
      console.error('Error posting message:', error);
      setStatus(prev => ({
        ...prev,
        error: 'Failed to post message. Please try again.'
      }));
    } finally {
      setStatus(prev => ({ ...prev, submitting: false }));
    }
  };

  const handleChange = (e) => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  return (
    <div className="contact-container">
      <div className="contact-header">
        <h1 className="text-3xl font-bold mb-2">Contact Us</h1>
        <p className="text-gray-600">We're here to help and answer any questions you might have</p>
      </div>

      <div className="contact-methods">
        {contactMethods.map((method, index) => (
          <ContactMethod key={index} {...method} />
        ))}
      </div>

      {/* Message Form */}
      <div className="message-section">
        <h2 className="text-2xl font-semibold mb-6">Leave a Message</h2>
        
        {status.error && (
          <div className="mb-4 p-4 bg-red-100 text-red-700 rounded-lg">
            {status.error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Name
              </label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="form-input"
                required
                disabled={status.submitting}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Email
              </label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="form-input"
                required
                disabled={status.submitting}
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Message
            </label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              rows="8"
              className="message-textarea"
              placeholder="Please write your message here..."
              required
              disabled={status.submitting}
            ></textarea>
          </div>

          <button
            type="submit"
            className={`submit-button w-full ${
              status.submitting 
                ? 'opacity-70 cursor-not-allowed' 
                : 'hover:bg-blue-700'
            }`}
            disabled={status.submitting}
          >
            {status.submitting ? (
              <span className="flex items-center justify-center">
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Posting...
              </span>
            ) : (
              'Send Message'
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

const ContactMethod = ({ icon, title, description, link }) => {
  return (
    <a 
      href={link}
      className="contact-method-card"
    >
      <div className="text-blue-600 bg-blue-50 p-4 rounded-full mb-4">
        {icon}
      </div>
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </a>
  );
};

export default Contact;