// src/components/auth/AuthRoute.js

import React from 'react';
import { useAuth } from './AuthContext';
import PropTypes from 'prop-types';

const AuthRoute = ({ children }) => {
    const auth = useAuth();
    
    // 等待认证状态初始化完成
    if (auth.loading || !auth.isInitialized) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <div className="spinner" />
            </div>
        );
    }

    // 调用children函数，传入auth状态
    return children(auth);
};

AuthRoute.propTypes = {
    children: PropTypes.func.isRequired,
};

export default AuthRoute;
