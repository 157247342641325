// comp/syncFavorites.js

import Cookies from 'js-cookie';
import { getFavoritesFromIndexedDB, saveFavoritesToIndexedDB } from './indexeddb';
import { createApiInstance } from './auth/axiosConfig';

// 时间格式转换的辅助函数
const formatDateTime = (dateTimeString) => {
    return new Date(dateTimeString).toISOString();
};

// 检查用户认证状态的辅助函数
const isAuthenticatedUser = () => {
    const sessionId = Cookies.get('sessionid');
    const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
    return !!sessionId && isAuthenticated;
};

const formatServerFavorite = (serverFav) => ({
    ...serverFav,
    keyPoints: serverFav.key_points,
    created_at: formatDateTime(serverFav.created_at),
    updated_at: formatDateTime(serverFav.updated_at)
});

// 主同步函数
export const syncFavorites = async () => {
    if (!navigator.onLine) {
        console.log('Offline: using local data');
        return getFavoritesFromIndexedDB();
    }

    try {
        const localFavorites = await getFavoritesFromIndexedDB();
        const apiInstance = createApiInstance();
        
        const response = await apiInstance.get('/sort/sync_favorites/');
        const serverFavorites = response.data;
        
        const localFavoritesMap = new Map(
            localFavorites.map(fav => [fav.url, fav])
        );
        
        const mergedFavorites = serverFavorites.map(serverFav => {
            const localFav = localFavoritesMap.get(serverFav.url);
            if (localFav) {
                const serverTime = new Date(serverFav.updated_at).getTime();
                const localTime = new Date(localFav.updated_at).getTime();
                
                // 如果本地收藏缺少时间戳，使用服务器的时间戳
                if (!localFav.created_at || !localFav.updated_at) {
                    return formatServerFavorite(serverFav);
                }
                
                return serverTime > localTime ? formatServerFavorite(serverFav) : localFav;
            }
            return formatServerFavorite(serverFav);
        });

        // 保留只存在于本地的收藏，但不添加时间戳
        // 等待下次服务器同步时获取正确的时间戳
        localFavorites.forEach(localFav => {
            if (!serverFavorites.some(serverFav => serverFav.url === localFav.url)) {
                mergedFavorites.push(localFav);
            }
        });

        await saveFavoritesToIndexedDB(mergedFavorites);
        return mergedFavorites;

    } catch (error) {
        console.error('Error syncing favorites:', error);
        if (error.response) {
            console.error('Error response:', error.response.data);
        }
        return getFavoritesFromIndexedDB();
    }
};

// 添加网络状态监听（可选）
let isOnline = navigator.onLine;

window.addEventListener('online', () => {
    isOnline = true;
    // 可以在这里添加自动重新同步的逻辑
});

window.addEventListener('offline', () => {
    isOnline = false;
}); 