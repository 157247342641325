// components/utils/imageUtils.js

/**
 * 处理图片URL，确保URL可以正确加载
 * @param {string} url - 原始图片URL
 * @returns {string|null} - 处理后的图片URL或null
 */
export const processImageUrl = (url) => {
    if (!url) {
        console.log('processImageUrl: URL is null or undefined');
        return null;
    }
    
    console.log('processImageUrl: Processing URL:', url);
    
    // 处理相对路径
    if (url.startsWith('/')) {
        const baseUrl = new URL(window.location.href).origin;
        const processedUrl = `${baseUrl}${url}`;
        console.log('processImageUrl: Processed relative URL to:', processedUrl);
        return processedUrl;
    }
    
    console.log('processImageUrl: Using original URL:', url);
    return url;
};

/**
 * 处理图片加载错误
 * @param {Event} event - 错误事件对象
 * @param {string} url - 图片URL（用于日志记录）
 */
export const handleImageError = (event, url) => {
    console.error('Image failed to load:', {
        originalUrl: url,
        processedUrl: event.target.src,
        baseUrl: window.location.origin
    });
    event.target.style.display = 'none';
    event.target.parentElement.style.display = 'none';
};

/**
 * 处理图片加载成功
 * @param {Event} event - 加载事件对象
 * @param {string} url - 图片URL（用于日志记录）
 */
export const handleImageLoad = (event, url) => {
    console.log('Image loaded successfully:', url);
    event.target.style.display = 'block';
};

/**
 * 获取图片容器样式
 * @returns {Object} - 图片容器的样式对象
 */
export const getImageContainerStyle = () => ({
    position: 'relative',
    width: '100%',
    maxHeight: '300px',
    overflow: 'hidden',
    marginBottom: '15px',
    backgroundColor: '#f5f5f5'
});

/**
 * 获取图片样式
 * @returns {Object} - 图片元素的样式对象
 */
export const getImageStyle = () => ({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    display: 'block'
}); 