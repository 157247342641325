// components/NewsList.js

import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import {
  Box,
  Tooltip,
  LinearProgress,
  Card,
  CardHeader,
  CardActions,
  Button,
  Typography,
  CircularProgress,
  Alert,
  Pagination,
  Chip,
  Snackbar,
  IconButton
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import '../css/NewsList.css';
import BrowserIdService from './browserIdService';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useAuth } from './auth/AuthContext';
import { createApiInstance } from './auth/axiosConfig';

const MAX_RETRIES = 3;
const BASE_URL = process.env.REACT_APP_API_URL;

const NewsList = () => {
  const theme = useTheme();
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [lastUpdated, setLastUpdated] = useState(new Date());
  const [isRefreshing, setIsRefreshing] = useState(false);

  const showNotification = useCallback((message, severity = 'error') => {
    setSnackbar({ open: true, message, severity });
  }, []);

  const handleCloseSnackbar = useCallback(() => {
    setSnackbar(prev => ({ ...prev, open: false }));
  }, []);

  const api = useMemo(() => createApiInstance(), []);

  const fetchNews = useCallback(async (pageNum) => {
    const requestStartTime = new Date().toISOString();
    const browserId = BrowserIdService.getBrowserId();
    
    console.log(`[${requestStartTime}] 开始发起新闻获取请求`);
    
    // 构建请求配置
    const requestConfig = {
      headers: {
        ...api.defaults.headers,
        'X-Browser-ID': browserId
      },
      timeout: api.defaults.timeout,
      withCredentials: api.defaults.withCredentials
    };
    
    console.log('请求配置:', {
      url: `${BASE_URL}/sort/getNews/?page=${pageNum}`,
      method: 'GET',
      ...requestConfig
    });
  
    try {
      setLoading(true);
      setError(null);
      
      const response = await api.get(`/sort/getNews/?page=${pageNum}`, requestConfig);
      
      console.log('API 响应详情:', {
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        data: response.data
      });
  
      const data = response.data;
  
      if (data.status === 'success') {
        let newsData = data.summarized_news?.summarized_news || data.summarized_news;
        let paginationData = data.summarized_news?.pagination || data.pagination;
  
        if (Array.isArray(newsData)) {
          setNews(newsData);
          if (paginationData) {
            setTotalPages(paginationData.total_pages);
          }
          console.log('数据设置成功');
        } else {
          throw new Error('Invalid news data format');
        }
      } else {
        console.warn('API 返回非成功状态:', data);
        throw new Error(data.message || 'API returned error status');
      }
    } catch (err) {
      console.error(`[${new Date().toISOString()}] 请求发生错误:`, {
        browserId: BrowserIdService.getBrowserId(),
        message: err.message,
        code: err.code,
        name: err.name,
        stack: err.stack,
        config: err.config,
        response: {
          status: err.response?.status,
          statusText: err.response?.statusText,
          headers: err.response?.headers,
          data: err.response?.data
        }
      });
      
      if (err.response?.status === 502) {
        console.error('502 错误详细信息:', {
          browserId: BrowserIdService.getBrowserId(),
          requestURL: err.config?.url,
          requestMethod: err.config?.method,
          requestHeaders: err.config?.headers,
          responseType: err.response?.headers?.['content-type'],
          cloudflareRay: err.response?.headers?.['cf-ray'],
          retryAttempt: retryCount + 1,
          totalRetries: MAX_RETRIES
        });
      }
  
      const errorMessage = err.response?.status === 502 
        ? 'Server is temporarily unavailable. Please try again later.'
        : err.message || 'Failed to fetch news';
      
      setError(errorMessage);
      showNotification(errorMessage);
      
      if (retryCount < MAX_RETRIES) {
        const nextRetryCount = retryCount + 1;
        console.log(`准备第 ${nextRetryCount} 次重试`);
        setRetryCount(nextRetryCount);
      } else {
        console.log('已达到最大重试次数，停止重试');
      }
    } finally {
      const requestEndTime = new Date().toISOString();
      console.log(`[${requestEndTime}] 请求结束，当前browser_id: ${BrowserIdService.getBrowserId()}`);
      setLoading(false);
    }
  }, [api, retryCount, showNotification]);

  // 添加刷新函数
  const refreshNews = useCallback(async () => {
    if (isRefreshing) return;
    
    try {
      setIsRefreshing(true);
      setError(null);
      await fetchNews(page);
      setLastUpdated(new Date());
      showNotification('News list has been updated', 'success');
    } catch (error) {
      console.error('Error refreshing news:', error);
      showNotification('Failed to refresh news', 'error');
    } finally {
      setIsRefreshing(false);
    }
  }, [fetchNews, page, showNotification, isRefreshing]);

  // 监听收藏变化
  useEffect(() => {
    const handleFavoriteChange = () => {
      console.log('Favorite changed, refreshing news list...');
      refreshNews();
    };

    window.addEventListener('favoriteChanged', handleFavoriteChange);

    return () => {
      window.removeEventListener('favoriteChanged', handleFavoriteChange);
    };
  }, [refreshNews]);

  useEffect(() => {
    const browserId = BrowserIdService.getBrowserId();
    console.log('Component mounted, current browser_id:', browserId);
    
    const controller = new AbortController();
    fetchNews(page);
    
    return () => {
      controller.abort();
      console.log('Component unmounted, final browser_id:', BrowserIdService.getBrowserId());
    };
  }, [page, fetchNews]);

  const handlePageChange = useCallback((event, value) => {
    setPage(value);
    window.scrollTo(0, 0);
  }, []);

  const handleRetry = useCallback(() => {
    setRetryCount(0);
    setError(null);
    fetchNews(page);
  }, [fetchNews, page]);

  const NewsCard = ({ item, theme, api }) => {
    const { user } = useAuth();
    const cardRef = useRef(null);
    const timeoutRef = useRef(null);
    const hasMarkedAsRead = useRef(false);
    const [shouldLoadImage, setShouldLoadImage] = useState(false);
  
    useEffect(() => {
      if (!cardRef.current) return;
  
      // 创建一个观察器来同时处理阅读记录和图片加载
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              // 处理图片加载
              setShouldLoadImage(true);
  
              // 处理阅读记录
              if (user && !hasMarkedAsRead.current) {
                timeoutRef.current = setTimeout(async () => {
                  try {
                    await api.post('/sort/read-history/mark-read/', {
                      article_id: item.article_id,
                      title: item.title,
                      url: item.url
                    });
                    hasMarkedAsRead.current = true;
                  } catch (error) {
                    console.error('Error marking article as read:', error);
                  }
                }, 3000);
              }
            } else {
              if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
              }
            }
          });
        },
        {
          threshold: 0.7,
          rootMargin: '50px' // 提前加载图片
        }
      );
  
      observer.observe(cardRef.current);
  
      return () => {
        observer.disconnect();
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    }, [user, item, api]);
  
    return (
      <Card 
        ref={cardRef}
        className="news-card"
        sx={{
          transition: 'transform 0.2s ease-in-out',
          '&:hover': {
            transform: 'translateY(-4px)',
            boxShadow: theme.shadows[4],
            cursor: 'pointer'
          }
        }}
        onClick={() => window.open(item.url, '_blank', 'noopener,noreferrer')}
        role="link"
        tabIndex={0}
        onKeyPress={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            window.open(item.url, '_blank', 'noopener,noreferrer');
          }
        }}
      >
        <CardHeader
          className="news-card-header"
          title={item.title}
          subheader={
            <div className="news-meta" onClick={(e) => e.stopPropagation()}>
              {item.source && (
                <span className="news-source">
                  {item.source}
                </span>
              )}
              {item.publish_date && (
                <span className="news-date">
                  {new Date(item.publish_date).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric'
                  })}
                </span>
              )}
              {item.keyword && (
                <Chip
                  label={item.keyword}
                  size="small"
                  className="news-keyword-chip"
                  onClick={(e) => e.stopPropagation()}
                />
              )}
            </div>
          }
        />
        {item.image_url && (
          <div 
            className="news-thumbnail-container"
            onClick={(e) => {
              e.stopPropagation();
              window.open(item.url, '_blank', 'noopener,noreferrer');
            }}
          >
            {shouldLoadImage ? (
              <>
                <img
                  src={item.image_url}
                  alt={item.title}
                  className="news-thumbnail"
                  onError={(e) => {
                    e.target.parentElement.style.display = 'none';
                  }}
                />
                <div className="thumbnail-overlay">
                  <Typography variant="caption" color="white">
                    View Original Article
                  </Typography>
                </div>
              </>
            ) : (
              <div className="thumbnail-placeholder">
                <CircularProgress size={24} />
              </div>
            )}
          </div>
        )}
        <CardActions className="news-card-actions">
          <Button
            className="news-view-button"
            size="small"
            color="primary"
            variant="outlined"
            onClick={(e) => {
              e.stopPropagation();
              window.open(item.url, '_blank', 'noopener,noreferrer');
            }}
          >
            Read Original Article
          </Button>
        </CardActions>
      </Card>
    );
  };

  if (loading && !news.length) {
    return (
      <div className="news-loading">
        <CircularProgress />
        <Typography variant="body2" sx={{ mt: 2 }}>
          Loading news...
        </Typography>
      </div>
    );
  }


  return (
    <div className="news-container">
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Typography variant="h5" className="news-title" sx={{ flex: 1 }}>
          Related News
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography variant="caption" color="text.secondary">
            Last updated: {lastUpdated.toLocaleTimeString()}
          </Typography>
          <Tooltip title="Refresh news">
            <IconButton 
              onClick={refreshNews}
              disabled={isRefreshing}
              size="small"
            >
              <RefreshIcon 
                sx={{
                  animation: isRefreshing ? 'spin 1s linear infinite' : 'none',
                  '@keyframes spin': {
                    '0%': { transform: 'rotate(0deg)' },
                    '100%': { transform: 'rotate(360deg)' },
                  },
                }}
              />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      {isRefreshing && (
        <LinearProgress 
          sx={{ 
            mb: 2,
            borderRadius: 1,
            height: 2
          }} 
        />
      )}
      
      {error && (
        <Alert
          severity="error"
          className="news-error"
          action={
            <Button color="inherit" size="small" onClick={handleRetry}>
              Retry
            </Button>
          }
        >
          {error}
        </Alert>
      )}

      {(!news || !news.length) && !loading ? (
        <Alert severity="info" className="news-empty">
          No news articles available.
        </Alert>
      ) : (
        <>
          <div className="news-masonry-grid">
            <div className="news-masonry-column">
              {news.filter((_, index) => index % 2 === 0).map((item) => (
                <NewsCard key={item.article_id || item.id} item={item} theme={theme} api={api}/>
              ))}
            </div>
            <div className="news-masonry-column">
              {news.filter((_, index) => index % 2 === 1).map((item) => (
                // Same Card component as above
                <NewsCard key={item.article_id || item.id} item={item} theme={theme} api={api}/>
              ))}
            </div>
          </div>
          
          {totalPages > 1 && (
            <Box className="pagination-container">
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
                size="large"
                className="news-pagination"
              />
            </Box>
          )}
        </>
      )}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default NewsList;