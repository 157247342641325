// src/components/auth/LoginForm.js

import React from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Mail, Lock, Eye, EyeOff, AlertCircle, Loader2 } from 'lucide-react';
import { useAuth } from './AuthContext';
import Cookies from 'js-cookie';
import '../../css/LoginForm.css';

const FormInput = ({
  icon: Icon,
  register,
  name,
  validation,
  type,
  placeholder,
  error,
  showPasswordToggle,
  onTogglePassword,
  showPassword
}) => (
  <div className="form-input-container">
    <div className="input-wrapper">
      <input
        {...register(name, validation)}
        type={type}
        className={`form-input ${showPasswordToggle ? 'with-toggle' : ''} ${
          error ? 'input-error' : ''
        }`}
        placeholder={placeholder}
      />
      <div className="input-icon-container">
        <Icon className="input-icon" />
      </div>
      {showPasswordToggle && (
        <button
          type="button"
          className="password-toggle-button"
          onClick={onTogglePassword}
          aria-label={showPassword ? 'Hide password' : 'Show password'}
        >
          {showPassword ? (
            <EyeOff className="toggle-icon" />
          ) : (
            <Eye className="toggle-icon" />
          )}
        </button>
      )}
    </div>
    {error && (
      <p className="error-message">{error.message}</p>
    )}
  </div>
);

export const LoginForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useAuth();
  const [showPassword, setShowPassword] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [serverError, setServerError] = React.useState('');
  const [fieldErrors, setFieldErrors] = React.useState({});

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
      remember: false
    }
  });

  const handleServerErrors = (error) => {
    const errorData = error.response?.data;
    
    if (errorData?.errors) {
      Object.entries(errorData.errors).forEach(([field, messages]) => {
        if (Array.isArray(messages) && messages.length > 0) {
          setError(field === 'login' ? 'email' : field, {
            type: 'server',
            message: messages[0]
          });
        }
      });
    }
    
    const errorMessage = errorData?.message || 
                        errorData?.error || 
                        error.message || 
                        'Login failed. Please try again.';
    setServerError(errorMessage);
  };

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    setServerError('');
    setFieldErrors({});

    try {
      const response = await login({
        email: data.email,
        password: data.password,
        remember: data.remember
      });

      // 登录成功
      if (response.success) {
        // 清除browser_id相关的所有存储
        Cookies.remove('browser_id');
        localStorage.removeItem('browser_id');
        sessionStorage.removeItem('browser_id');
        
        // 设置认证状态
        localStorage.setItem('isAuthenticated', 'true');
        
        // 记录日志
        console.log('Successfully logged in and cleared browser_id');
        
        // 延迟一小段时间确保cookie被清除
        await new Promise(resolve => setTimeout(resolve, 100));
        
        // 重新加载页面以确保所有状态都被重置
        const redirectTo = response.location || location.state?.from?.pathname || '/app';
        window.location.href = redirectTo;
        return;
      }

      setServerError('Login failed. Please try again.');
      
    } catch (error) {
      console.error('Login error:', error);
      
      if (error.response?.data?.errors) {
        const { errors } = error.response.data;
        Object.entries(errors).forEach(([field, messages]) => {
          if (Array.isArray(messages) && messages.length > 0) {
            setError(field === 'login' ? 'email' : field, {
              type: 'server',
              message: messages[0]
            });
          }
        });
      }
      
      setServerError(
        error.response?.data?.message || 
        error.message || 
        'An error occurred during login'
      );
    } finally {
      setIsSubmitting(false);
    }
  };
  
  const getFieldError = (fieldName) => {
    return errors[fieldName] || fieldErrors[fieldName];
  };

  return (
    <div className="login-page">
      <div className="login-card">
        <div className="card-header">
          <h2 className="title">Welcome back</h2>
          <p className="subtitle">
            Or{' '}
            <Link to="/register" className="link">
              create a new account
            </Link>
          </p>
        </div>

        {serverError && (
          <div className="error-alert">
            <AlertCircle className="alert-icon" />
            <span className="alert-message">{serverError}</span>
          </div>
        )}

        <form className="login-form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="form-fields">
            <FormInput
              icon={Mail}
              register={register}
              name="email"
              validation={{
                required: 'Email is required',
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: 'Please enter a valid email'
                }
              }}
              type="email"
              placeholder="Email address"
              error={getFieldError('email')}
            />

            <FormInput
              icon={Lock}
              register={register}
              name="password"
              validation={{
                required: 'Password is required',
                minLength: {
                  value: 8,
                  message: 'Password must be at least 8 characters'
                }
              }}
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              error={getFieldError('password')}
              showPasswordToggle
              onTogglePassword={() => setShowPassword(!showPassword)}
              showPassword={showPassword}
            />
          </div>

          <div className="form-options">
            <label className="remember-option">
              <input
                {...register('remember')}
                type="checkbox"
                className="remember-checkbox"
                id="remember-me"
              />
              <span className="remember-text">Remember me</span>
            </label>

            <Link 
              to="/accounts/password/reset/" 
              className="forgot-link"
            >
              Forgot your password?
            </Link>
          </div>

          <div className="submit-container">
            <button
              type="submit"
              disabled={isSubmitting}
              className="submit-button"
            >
              {isSubmitting ? (
                <span className="loading-state">
                  <Loader2 className="spinner" />
                  <span>Signing in...</span>
                </span>
              ) : (
                'Sign in'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;