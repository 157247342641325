// App.js
import React, { useState, useEffect, useMemo } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import './App.css';
import Header from './components/Header';
import LandingPage from './components/LandingPage';
import Features from './components/Features';
import HelpCenter from './components/HelpCenter';
import Contact from './components/Contact';
import TermsOfService from './legal/TermsOfService';
import PrivacyPolicy from './legal/PrivacyPolicy';
import RefundPolicy from './legal/RefundPolicy';
import CookiePolicy from './legal/CookiePolicy';
import CookieConsent from './components/CookieConsent';
import Modal from 'react-modal';
import Footer from './components/Footer';
import MainLayout from './components/MainLayout';
import { saveFavoritesToIndexedDB, getFavoritesFromIndexedDB, saveEmbeddingToIndexedDB, saveCategoryEmbeddingToIndexedDB, getEmbeddingFromIndexedDB, getCategoriesFromIndexedDB,getCategoryEmbeddingFromIndexedDB } from './components/indexeddb';
import { handleCompareWithCategories, generateCategoryEmbedding } from './components/categoryUtils';
import ChatPage from './components/ChatPage';
import { generateQuery } from './components/generateQuery';
import { AuthProvider, ProtectedRoute } from './components/auth/AuthContext';
import AuthRoute from './components/auth/AuthRoute';
import {
    LoginForm,
    RegisterForm,
    VerifyEmail,
    AccountSettings,
    EmailVerifiedSuccess,
    EmailVerifiedError,
    EmailConfirmation,
    AUTH_ROUTES
  } from './components/auth';
import ErrorBoundary from './components/ErrorBoundary';
import { createApiInstance, setupCSRF } from './components/auth/axiosConfig';
import { ToastProvider, handleApiResponse } from './components/Toast';
import PricingPage from './components/Pricing';

function App() {
    Modal.setAppElement('#root');
    
    const [url, setUrl] = useState('');
    const [summary, setSummary] = useState('');       
    const [keyPoints, setKeyPoints] = useState([]);   
    const [category, setCategory] = useState('');     
    const [embedding, setEmbedding] = useState(null);
    const [loading, setLoading] = useState(false);
    const [favorites, setFavorites] = useState([]);
    const [filteredFavorites, setFilteredFavorites] = useState([]);
    const apiUrl = process.env.REACT_APP_API_URL;

    const apiInstance = useMemo(() => createApiInstance(apiUrl), [apiUrl]);

    // 提取所有分类（不重复）
    const categories = [...new Set(favorites.map(fav => fav.category))];

    // 根据分类过滤收藏内容
    const filterFavoritesByCategory = (category) => {
        if (!category) {
          setFilteredFavorites(favorites); // Show all favorites when no category is selected
        } else {
          const filtered = favorites.filter(fav => fav.category === category);
          setFilteredFavorites(filtered);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!url || url.trim() === '') {
            handleApiResponse.emptyUrl();
            return;
        }
    
        try {
            new URL(url.trim());
        } catch (e) {
            handleApiResponse.invalidUrl();
            return;
        }
        
        setLoading(true);
    
        try {
            // Log the request payload
            const payload = { url: url.trim() };
            console.log('Request payload:', payload);
            
            // Ensure proper content type and format
            const processResponse = await apiInstance.post('/sort/process/', payload, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            
            console.log('Response:', processResponse);
            
            if (!processResponse.data) {
                throw new Error('No data received from server');
            }
            
            const {
                title: newTitle,
                summary: newSummary,
                category: newCategoryFromGPT,
                key_points: newKeyPoints,
                embedding: newEmbedding,
                main_image_url: newMainImageUrl
            } = processResponse.data;
    
            // Validate required fields
            if (!newTitle || !newSummary || !newCategoryFromGPT) {
                throw new Error('Missing required fields in response');
            }
    
            // Rest of your existing code...
            console.log(`title: ${newTitle}`);
            console.log(`key_points: ${newKeyPoints}`);
            
            setSummary(newSummary);
            setKeyPoints(newKeyPoints);
            setEmbedding(newEmbedding);
        
            // Save embedding to IndexedDB
            await saveEmbeddingToIndexedDB(url, newEmbedding);
        
            // 获取与分类比较的最佳分类
            const bestCategory = await handleCompareWithCategories(
                newEmbedding, 
                getCategoriesFromIndexedDB, 
                getCategoryEmbeddingFromIndexedDB 
            );
        
            let finalCategory;
            if (bestCategory && bestCategory.similarity >= 0.3) {
                // 如果有相似度高于0.3的分类，使用本地的分类
                finalCategory = bestCategory.name;
                setCategory(finalCategory);
                console.log(`Using best matching category: ${finalCategory}`);
            } else {
                // 如果相似度都低于0.3，使用GPT返回的分类
                finalCategory = newCategoryFromGPT;
                setCategory(finalCategory);
        
                // 调用生成新的分类嵌入
                const newCategoryEmbedding = await generateCategoryEmbedding(
                    newCategoryFromGPT,
                    apiUrl,
                    Cookies.get('csrftoken')
                );
                console.log(`Generated new category embedding for: ${newCategoryFromGPT}`);
        
                // 保存新的分类嵌入到IndexedDB
                await saveCategoryEmbeddingToIndexedDB(newCategoryFromGPT, newCategoryEmbedding);
                console.log(`Saved category embedding for: ${newCategoryFromGPT}`);
            }
        
            // 保存新的摘要和分类到收藏夹中
            const newFavorite = {
                url, 
                title: newTitle, 
                summary: newSummary, 
                keyPoints: newKeyPoints, 
                category: finalCategory,
                categoryFromGPT: newCategoryFromGPT,
                main_image_url: newMainImageUrl,
                isNew: true
            };
            
            const newFavorites = [newFavorite, ...favorites];
            setFavorites(newFavorites);
            setFilteredFavorites(newFavorites);

            // Save the updated favorites to IndexedDB
            await saveFavoritesToIndexedDB(newFavorites);

            // Generate query based on title and category
            const query = await generateQuery(url, newTitle, finalCategory);

            // 触发收藏变化事件
            window.dispatchEvent(new CustomEvent('favoriteChanged', {
                detail: {
                    type: 'add',
                    favorite: newFavorite
                }
            }));

            handleApiResponse.success();

            // After a short delay, remove the isNew flag
            setTimeout(() => {
                setFavorites(prevFavorites => 
                    prevFavorites.map(fav => 
                        fav.url === url ? { ...fav, isNew: false } : fav
                    )
                );
            }, 5000); // Remove the 'new' status after 5 seconds

        } catch (error) {
            handleApiResponse.error(error);
        } finally {
            setLoading(false);
        }
    };

    // Layout component to avoid repetition
    const PageLayout = ({ children, title }) => (
        <>
        <Header title={title} />
        {children}
        <Footer />
        </>
    );

    console.log('Current API URL:', apiUrl);

    useEffect(() => {
        const initialize = async () => {
            try {
                console.log('Starting initialization...');
                // 初始化CSRF token
                await setupCSRF(apiUrl);
                console.log('CSRF setup completed');
                
                // 加载收藏夹数据
                const storedFavorites = await getFavoritesFromIndexedDB();
                console.log('Loaded favorites:', storedFavorites);
                setFavorites(storedFavorites);
                setFilteredFavorites(storedFavorites);
            } catch (error) {
                console.error('Error during initialization:', error);
            }
        };
    
        initialize();
    
        if (!apiUrl) {
            console.warn('REACT_APP_API_URL is not defined, using relative paths');
        } else {
            console.log(`Using API URL: ${apiUrl}`);
        }
    }, [apiUrl]);
    
    return (
        <ErrorBoundary>
        <AuthProvider>
            <Router>
                <div className="App">
                    <Routes>
                        <Route path={AUTH_ROUTES.LOGIN} element={<LoginForm />} />
                        <Route path={AUTH_ROUTES.REGISTER} element={<RegisterForm />} />
                        <Route path={AUTH_ROUTES.VERIFY_EMAIL} element={<VerifyEmail />} />
                        <Route path={AUTH_ROUTES.EMAIL_VERIFIED_SUCCESS} element={<EmailVerifiedSuccess />} />
                        <Route path={AUTH_ROUTES.EMAIL_VERIFIED_ERROR} element={<EmailVerifiedError />} />
                        <Route 
                            path={AUTH_ROUTES.CONFIRM_EMAIL} 
                            element={<EmailConfirmation />} 
                        />
                        <Route
                            path={AUTH_ROUTES.ACCOUNT_SETTINGS} 
                            element={
                                <ProtectedRoute>
                                    <PageLayout title="Account Settings">
                                        <AccountSettings />
                                    </PageLayout>
                                </ProtectedRoute>
                            }
                        />
                        {/* <Route path="/" element={<LandingPage />} /> */}
                        <Route 
                            path="/" 
                            element={
                                <AuthRoute>
                                    {({ user }) => 
                                        user ? <Navigate to="/app" replace /> : <LandingPage />
                                    }
                                </AuthRoute>
                            } 
                        />
                        <Route path="/app" element={
                            <>
                                <Header title="LinkSort.ai" />
                                <MainLayout 
                                    url={url} 
                                    setUrl={setUrl} 
                                    handleSubmit={handleSubmit} 
                                    loading={loading}
                                    favorites={filteredFavorites}
                                    setFavorites={setFavorites}    
                                    setFilteredFavorites={setFilteredFavorites}
                                    categories={categories} 
                                    filterFavoritesByCategory={filterFavoritesByCategory}
                                />
                                <Footer />
                            </>
                        } />

                        <Route path="/features" element={<Features />} />
                        <Route path="/help" element={<HelpCenter />} />
                        <Route path="/contact" element={<Contact />} />
                        
                        <Route path="/terms-of-service" element={
                            <>
                                <Header title="LinkSort.ai" />
                                <TermsOfService />
                                <Footer />
                            </>
                        } />
                        <Route path="/privacy-policy" element={
                            <>
                                <Header title="LinkSort.ai" />
                                <PrivacyPolicy />
                                <Footer />
                            </>
                        } />
                        <Route path="/cookie-policy" element={
                            <>
                                <Header title="LinkSort.ai" />
                                <CookiePolicy />
                                <Footer />
                            </>
                        } />
                        <Route path="/refund-policy" element={
                            <>
                                <Header title="LinkSort.ai" />
                                <RefundPolicy />
                                <Footer />
                            </>
                        } />
                        <Route path="/chat/:url" element={
                            <>
                                <Header title="LinkSort.ai" />
                                <ChatPage />
                                <Footer />
                            </>
                        } />
                        <Route path="/pricing" element={
                            <>
                                <Header title="Pricing" />
                                <PricingPage />
                                <Footer />
                            </>
                        } />

                        {/* 404 Route */}
                        <Route path="*" element={<Navigate to="/" replace />} />
                    </Routes>
                    <CookieConsent />
                    <ToastProvider />
                </div>
            </Router>
        </AuthProvider>
        </ErrorBoundary>
    );
}

export default App;
