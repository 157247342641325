// src/components/Pricing.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Check } from 'lucide-react';
import '../css/Pricing.css';

const PricingPage = () => {
  const navigate = useNavigate();
  const [selectedPlan] = useState('monthly'); // 只提供月付选项

  const features = [
    'Unlimited summaries',
    'Cross-device sync',
    'Priority support',
    'Advanced AI features',
    'Custom categories'
  ];

  const handleUpgrade = () => {
    // 直接处理支付逻辑，不需要登录检查
    // TODO: Implement payment logic
  };

  return (
    <div className="pricing-container">
      <div className="pricing-header">
        <h1>Pricing</h1>
        <p>Choose a plan to buy credits.</p>
      </div>

      <div className="pricing-toggle">
        <button className="toggle-button active">Monthly</button>
      </div>

      <div className="pricing-cards">
        {/* Free Plan */}
        <div className="pricing-card">
          <div className="card-header">
            <h2>Basic</h2>
            <div className="price">
              <span className="currency">$</span>
              <span className="amount">0</span>
              <span className="period">/month</span>
            </div>
          </div>
          <div className="card-features">
            <ul>
              <li>
                <Check className="check-icon" />
                <span>5 summaries per day</span>
              </li>
              <li>
                <Check className="check-icon" />
                <span>Basic AI features</span>
              </li>
              <li>
                <Check className="check-icon" />
                <span>Single device</span>
              </li>
            </ul>
          </div>
          <button 
            className="upgrade-button outline"
            onClick={() => navigate('/register')}
          >
            Get Started
          </button>
        </div>

        {/* Pro Plan */}
        <div className="pricing-card pro">
          <div className="popular-badge">Popular</div>
          <div className="card-header">
            <h2>Pro</h2>
            <div className="price">
              <span className="currency">$</span>
              <span className="amount">5</span>
              <span className="period">/month</span>
            </div>
          </div>
          <div className="card-features">
            <ul>
              {features.map((feature, index) => (
                <li key={index}>
                  <Check className="check-icon" />
                  <span>{feature}</span>
                </li>
              ))}
            </ul>
          </div>
          <button 
            className="upgrade-button primary"
            onClick={handleUpgrade}
          >
            Upgrade Plan
          </button>
        </div>
      </div>

      <div className="pricing-faq">
        <p className="money-back">No extra fees, no hidden charges—cancel anytime</p>
        <p className="support-text">
          Questions? Contact our support team at{' '}
          <a href="mailto:support@linksort.ai">support@linksort.ai</a>
        </p>
      </div>
    </div>
  );
};

export default PricingPage; 