import React from 'react';

const CookiePolicy = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Cookie Policy</h1>
      
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">What Are Cookies</h2>
        <p className="mb-4">
          Cookies are small text files that are stored on your computer or mobile device when you visit our website. 
          They are widely used to make websites work more efficiently and provide information to website owners.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">How We Use Cookies</h2>
        <p className="mb-4">LinkSort.ai uses cookies for the following purposes:</p>
        <ul className="list-disc pl-6 mb-4">
          <li className="mb-2">
            <strong>Essential Cookies:</strong> Required for the website to function properly, including authentication and security.
          </li>
          <li className="mb-2">
            <strong>Analytics Cookies:</strong> Help us understand how visitors interact with our website by collecting and reporting information anonymously.
          </li>
          <li className="mb-2">
            <strong>Functional Cookies:</strong> Enable enhanced functionality and personalization, such as remembering your preferences.
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Cookie Management</h2>
        <p className="mb-4">
          You can control and/or delete cookies as you wish. You can delete all cookies that are already on your computer and you can set most browsers to prevent them from being placed. However, if you do this, you may have to manually adjust some preferences every time you visit our site and some services and functionalities may not work.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Types of Cookies We Use</h2>
        <div className="mb-4">
          <h3 className="text-xl font-semibold mb-2">Strictly Necessary Cookies</h3>
          <p className="mb-2">These cookies are essential for you to browse the website and use its features, such as accessing secure areas of the site.</p>
          <ul className="list-disc pl-6 mb-4">
            <li>csrftoken - Security token for form submissions</li>
            <li>sessionid - Manages user sessions</li>
          </ul>
        </div>

        <div className="mb-4">
          <h3 className="text-xl font-semibold mb-2">Analytics and Performance Cookies</h3>
          <p className="mb-2">These cookies collect information about how you use our website.</p>
          <ul className="list-disc pl-6 mb-4">
            <li>Usage statistics</li>
            <li>Error monitoring</li>
          </ul>
        </div>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Updates to This Policy</h2>
        <p className="mb-4">
          We may update this Cookie Policy from time to time to reflect changes in our practices or for operational, legal, or regulatory reasons. We encourage you to periodically review this page for the latest information on our cookie practices.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
        <p className="mb-4">
          If you have any questions about our use of cookies, please contact us at{' '}
          <a href="mailto:support@linksort.ai" className="text-blue-600 hover:underline">
            support@linksort.ai
          </a>
        </p>
      </section>
    </div>
  );
};

export default CookiePolicy;